import { CommonModule } from '@angular/common';
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChildren,
  effect,
  ElementRef,
  inject,
  input,
  output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutLoadingBarComponent, CheckoutLoadingService } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { ButtonFormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { StepperComponent } from 'stepper';
import { TranslatePipe, TranslateService } from 'translate';
import { CheckoutSubstepComponent } from '../checkout-substep/checkout-substep.component';

@Component({
  selector: 'lib-checkout-step',
  templateUrl: './checkout-step.component.html',
  styleUrls: ['./checkout-step.component.scss'],
  imports: [
    CommonModule,
    SvgComponent,
    StepperComponent,
    ButtonFormFieldComponent,
    TranslatePipe,
    DynamicHooksComponent,
    CheckoutLoadingBarComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutStepComponent implements AfterContentInit, AfterContentChecked, AfterViewInit {
  flowService = inject(CheckoutFlowService);
  loadingService = inject(CheckoutLoadingService);
  checkoutBaseService = inject(CheckoutBaseService);
  checkoutSessionService = inject(CheckoutSessionService);
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  @ContentChildren(CheckoutSubstepComponent) substeps: QueryList<CheckoutSubstepComponent>;
  @ViewChild('acceptanceTermsConditionsContainer') private acceptanceTermsConditionsContainer: ElementRef;
  stepPath = input.required<string>();
  stepTitle = input<string>();
  productInformation = input.required<{ productFamily: string }>();
  loginModalClosed = input(false);
  showBottomContent = input(true);
  nextStep = output<void>();
  public showAcceptanceTermsConditions = false;
  public stepIndex = computed(() => this.flowService.getStepIndex(this.stepPath()));
  public totalSteps = 1;
  public firstSubstepIndex = computed(() => this.flowService.getFirstSubstepIndex(this.stepPath()));
  public totalSubsteps = 0;
  public showLegalText = false;
  termsConditionsContent = computed(() => {
    if (this.productInformation().productFamily === 'MOBILE' || this.checkoutSessionService.isMBB) {
      const content = this.translateService.getTranslation(['checkout_terms_conditions', 'mobile_content']);
      return content;
    }

    if (this.productInformation().productFamily === 'TV') {
      const content = this.translateService.getTranslation(['checkout_terms_conditions', 'tv_content']);
      return content;
    }

    const content = this.translateService.getTranslation(['checkout_terms_conditions', 'content']);
    return content;
  });

  constructor() {
    effect(() => {
      if (this.loginModalClosed() && !this.desktopView) {
        this.flowService.navigateToFirstSubStep(this.stepPath());
      }
    });
  }

  ngAfterContentInit() {
    this.totalSteps = this.flowService.getTotalSteps();
    this.totalSubsteps = this.flowService.getTotalSubsteps();

    if (this.substeps?.length > 0) {
      this.substeps.forEach((substep, index) => (substep.index = index));
    }
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.showAcceptanceTermsConditions = this.acceptanceTermsConditionsContainer?.nativeElement.children.length > 0;
  }

  ngAfterContentChecked() {
    this.showLegalText = this.flowService.finishingStep(this.stepPath());
    this.cdr.detectChanges();
  }

  get isAuthenticated() {
    return this.checkoutSessionService.isAuthenticated;
  }

  get firstSubstep(): boolean {
    return !!this.substeps.first?.active;
  }

  get firstStep(): boolean {
    return this.stepIndex() === 0;
  }

  get activeSubstepIndex(): number {
    return this.substeps.toArray().findIndex(substep => substep.active);
  }

  get desktopView(): boolean {
    return this.flowService.desktopView;
  }

  get substepTitle(): string {
    return this.substeps.get(this.activeSubstepIndex)?.substepTitle ?? '';
  }

  get submitButtonText(): string {
    if (this.desktopView && this.substeps.last?.submitButtonText) {
      return this.substeps.last.submitButtonText;
    }
    if (this.substeps.get(this.activeSubstepIndex)?.submitButtonText) {
      return this.substeps.get(this.activeSubstepIndex).submitButtonText;
    }

    return this.translateService.getTranslation(['checkout_details', 'next']) || 'Next';
  }

  toNextStep() {
    this.nextStep.emit();
  }

  back() {
    if (this.desktopView || this.firstSubstep) {
      this.flowService.navigateToPreviousStep(this.stepPath());
    } else {
      this.flowService.navigateToPreviousSubstep();
    }
  }

  next() {
    if (this.flowService.finishingStep(this.stepPath())) {
      this.toNextStep();
    } else {
      this.substeps.get(this.activeSubstepIndex).toNextSubstep();
    }
  }
}
