import { CommonModule } from '@angular/common';
import {
  APP_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  input,
  model,
  Output,
  signal,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutSessionService } from 'checkout-session';
import { FormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { App } from 'utils';

export type UserNeedsLogin = {
  value: boolean;
  clickedNext: boolean;
};

@Component({
  selector: 'lib-checkout-contact-details',
  templateUrl: './checkout-contact-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SvgComponent,
    FormFieldComponent,
    ReactiveFormsModule,
    TranslatePipe,
    MatInputModule,
    DynamicHooksComponent,
  ],
})
export class CheckoutContactDetailsComponent {
  private checkoutBaseService = inject(CheckoutBaseService);
  private checkoutSessionService = inject(CheckoutSessionService);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private appId = inject(APP_ID, { optional: true });
  @Input() formGroup: FormGroup<{ email: FormControl; contactPhone: FormControl }>;
  @Input() showFields = { email: true, contactPhone: true };
  existingCustomer = input<boolean>();
  existingContactDetails = input<{ email: string; contactPhone: string }>();
  phoneNumberInvalid = input(false);
  isEditingField = signal(false);
  @Input() userNeedsToLogin: UserNeedsLogin;
  isEditing = false;
  isBackendValid = model(true);
  @Input() validateEmail = false;
  @Input() previousInvalidEmail: string = null;
  @Output() contactEmailBlur = new EventEmitter<{ control: AbstractControl }>();
  @Output() contactPhoneBlur = new EventEmitter<{ control: AbstractControl }>();
  showEditablePhoneField = computed(
    () =>
      !this.existingCustomer() ||
      !this.existingContactDetails().contactPhone ||
      this.isEditingField() ||
      this.phoneNumberInvalid()
  );
  detailsPage = this.appId === App.pos ? 'pos_checkout_details' : 'checkout_details';

  get isAuthenticated() {
    return this.checkoutSessionService.isAuthenticated;
  }

  public get emailControl() {
    return this.formGroup.get('email');
  }

  public get phoneControl() {
    return this.formGroup.get('contactPhone');
  }

  public onContactEmailBlur(control: AbstractControl) {
    this.isEditing = false;
    this.contactEmailBlur.emit({ control });
  }

  public onContactPhoneBlur(control: AbstractControl) {
    this.contactPhoneBlur.emit({ control });
  }

  public onInput() {
    this.isEditing = true;
    const hasInvalidEmail = !!this.previousInvalidEmail;
    const previousValue = this.previousInvalidEmail ?? this.checkoutSessionService.contactEmail;
    if (hasInvalidEmail) {
      if (this.emailControl.value !== previousValue) {
        this.userNeedsToLogin.value = false;
      } else {
        this.userNeedsToLogin.value = true;
      }
    }

    this.cdr.markForCheck();
  }

  public manageRecognizedClient() {
    if (this.appId === App.pos) {
      return this.router.navigate(['../../../customer-find'], {
        relativeTo: this.route,
        queryParams: { email: this.emailControl.value },
      });
    }

    this.checkoutBaseService.openLoginModal({
      existingUser: true,
      email: this.emailControl.value,
      disableMfaOnboarding: true,
    });
  }

  toggleEditField() {
    this.isEditingField.set(!this.isEditingField());
  }
}
