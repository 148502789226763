import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { LanguageService } from 'language';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import { BrowserService, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';

@Component({
  selector: 'app-dummy',
  template: `<router-outlet></router-outlet>`,
  imports: [RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DummyComponent {
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private router = inject(Router);
  private languageService = inject(LanguageService);
  private browserService = inject(BrowserService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);

  constructor() {
    if (this.router.url === `/${this.languageService.current}`) {
      const message = this.translateService.getTranslation(['checkout', 'error_create_session']);
      this.toastService.add(message, false, 2000);
      setTimeout(() => this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}`), 2000);
    }
  }
}
