<ng-template #addressComponent>
  @if (checkoutDetailsService.showAddressForm) {
    <lib-checkout-address
      [formGroup]="checkoutDetailsService.addressForm"
      [addressLookupFields]="checkoutDetailsService.addressLookupFields()"
      [showFields]="{
        billingAddress: !checkoutDetailsService.fieldsToHide?.includes(
          checkoutDetailsService.detailsFields.BILLING_ADDRESS
        ),
        shippingAddress: !checkoutDetailsService.fieldsToHide?.includes(
          checkoutDetailsService.detailsFields.SHIPPING_ADDRESS
        ),
      }"
      [addresses]="checkoutDetailsService.addresses"
      [productInformation]="{
        productClass: checkoutSessionService.productSpecClass,
        productFamily: checkoutSessionService.productSpecFamily,
      }"
      [isMBB]="checkoutSessionService.isMBB"
      (addressFormBlur)="checkoutBaseService.checkFormErrorToAddAnalytics($event.field, $event.control)"
    ></lib-checkout-address>
  }
</ng-template>

<lib-checkout-step
  [stepTitle]="stepTitle()"
  [stepPath]="stepPath"
  (nextStep)="onNextClick(true)"
  [loginModalClosed]="flowService.loginModalClosed$ | async"
  [productInformation]="{ productFamily: checkoutSessionService.productSpecFamily }"
>
  <lib-checkout-substep
    [substepTitle]="userDetailsSubstepTitle()"
    [submitButtonText]="checkoutDetailsService.submitButtonLabel(existingCustomer())"
    (nextSubstep)="onNextClick()"
    data-testid="checkout-headline"
  >
    <div class="container md:p-0 lg:px-48" data-testid="checkout-start">
      <form [formGroup]="checkoutDetailsService.detailsForm">
        <div class="lg:grid lg:gap-x-4">
          <!-- contact -->
          <h3 id="contact" class="flex text-justify text-black mb-4 section-title">
            {{ ['checkout_details', 'contact'] | translate }}
          </h3>

          <lib-checkout-contact-details
            [formGroup]="checkoutDetailsService.contactDetailsForm"
            [showFields]="{
              email: !checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.EMAIL),
              contactPhone: !checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.PHONE),
            }"
            [validateEmail]="checkoutDetailsService.validateEmail()"
            [(isBackendValid)]="checkoutDetailsService.isEmailValidForBackend"
            [previousInvalidEmail]="checkoutDetailsService.previousInvalidEmail()"
            [existingCustomer]="existingCustomer()"
            [existingContactDetails]="{
              email: checkoutSessionService.personalDetails?.contactDetail?.email,
              contactPhone: checkoutSessionService.personalDetails?.contactDetail?.phoneNumber,
            }"
            [userNeedsToLogin]="checkoutDetailsService.userNeedsToLogin()"
            (contactEmailBlur)="updateContactEmail($event.control)"
            (contactPhoneBlur)="updateContactPhone($event.control)"
            [phoneNumberInvalid]="checkoutDetailsService.phoneNumberInvalid()"
          ></lib-checkout-contact-details>
          <lib-checkout-identification
            [formGroup]="checkoutDetailsService.identificationForm"
            [showFields]="{
              scanIdCta: !checkoutDetailsService.fieldsToHide?.includes(
                checkoutDetailsService.detailsFields.SCAN_ID_CTA
              ),
              firstName: !checkoutDetailsService.fieldsToHide?.includes(
                checkoutDetailsService.detailsFields.FIRST_NAME
              ),
              lastName: !checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.LAST_NAME),
              dateOfBirth: !checkoutDetailsService.fieldsToHide?.includes(
                checkoutDetailsService.detailsFields.DATE_OF_BIRTH
              ),
              nationality: !checkoutDetailsService.fieldsToHide?.includes(
                checkoutDetailsService.detailsFields.NATIONALITY
              ),
              idType: !checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.ID_TYPE),
              idNumber: !checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.ID_NUMBER),
              idExpiryDate: !checkoutDetailsService.fieldsToHide?.includes(
                checkoutDetailsService.detailsFields.ID_EXPIRY_DATE
              ),
            }"
            [productInformation]="{ productSpecCategory: checkoutSessionService.productSpecCategory }"
            [dateOfBirthOptions]="{
              maxDateOfBirth: checkoutDetailsService.maxDateOfBirth,
              minDateOfBirth: checkoutDetailsService.minDateOfBirth,
            }"
            [existingCustomer]="existingCustomer()"
            [sessionId]="checkoutSessionService.sessionId"
            [identificationDocument]="checkoutSessionService.idDocument"
            [personalDetails]="checkoutSessionService.personalDetails"
            (analyticsEvent)="checkoutBaseService.addAnalyticsEvent($event)"
            (fieldErrorBlur)="checkoutBaseService.checkFormErrorToAddAnalytics($event.field, $event.control)"
            (fieldBlur)="checkoutBaseService.addFieldSubmittedAnalytics($event.field, $event.value)"
          ></lib-checkout-identification>

          <!-- BILLING/SHIPPING ADDRESS LOGGED IN -->
          @if (existingCustomer()) {
            <ng-content *ngTemplateOutlet="addressComponent"></ng-content>
            @if (checkoutDetailsService.showFreeTvBox) {
              <lib-toggle-button
                class="my-6"
                label="{{ ['checkout_details', 'free_tv_box'] | translate }}"
                (toggleChange)="onFreeTvToggleChange($event)"
              ></lib-toggle-button>
            }
          }
        </div>
      </form>
    </div>
  </lib-checkout-substep>

  <lib-checkout-substep
    [substepTitle]="userAddressesSubstepTitle()"
    [submitButtonText]="checkoutDetailsService.submitButtonLabel(existingCustomer())"
    (nextSubstep)="onNextClick()"
    data-testid="order-button"
  >
    <div class="lg:px-48 mt-4">
      <!-- BILLING/SHIPPING ADDRESS NOT LOGGED IN -->
      @if (!existingCustomer()) {
        <div>
          <ng-content *ngTemplateOutlet="addressComponent"></ng-content>
          @if (checkoutDetailsService.showFreeTvBox) {
            <lib-toggle-button
              class="my-6"
              label="{{ ['checkout_details', 'free_tv_box'] | translate }}"
              (toggleChange)="onFreeTvToggleChange($event)"
            ></lib-toggle-button>
          }
        </div>
      }

      <div class="lg:grid lg:grid-cols-2 lg:gap-x-6">
        @if (
          !checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.OTO_ID) &&
          checkoutSessionService.otoIdState !== 'UNAVAILABLE'
        ) {
          <lib-checkout-fiber
            class="block lg:order-2 mb-8 lg:mb-0"
            [formGroup]="checkoutDetailsService.otoIdForm"
            [autoFocus]="checkoutDetailsService.otoIdFieldFocus()"
            (fiberFormBlur)="checkoutBaseService.checkFormErrorToAddAnalytics($event.field, $event.control)"
            (analyticsEvents)="checkoutBaseService.addAnalyticsEvent($event.interaction, $event.extraFields)"
          ></lib-checkout-fiber>
        }

        @if (!checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.ACTIVATION_DATE)) {
          <form [formGroup]="checkoutDetailsService.wishDateForm">
            <span class="flex text-black mb-5 section-title">
              {{ ['checkout_details', 'activation_title'] | translate }}</span
            >

            <lib-date-picker-form-field
              (onBlur)="
                checkoutBaseService.checkFormErrorToAddAnalytics('activation date', checkoutDetailsService.wishDate)
              "
              class="w-full"
              label="{{ ['checkout_details', 'activation_date_title'] | translate }}"
              formControlName="wishDate"
              placeholder="{{ ['checkout_details', 'activation_date_title'] | translate }}"
              [startCalendarDateAt]="checkoutDetailsService.startCalendarActivationDateAt"
              [maxDate]="checkoutDetailsService.maxActivationDate"
              [minDate]="checkoutDetailsService.minActivationDate"
              data-testid="wish-date"
            >
            </lib-date-picker-form-field>
          </form>
        }

        @if (!checkoutDetailsService.fieldsToHide?.includes(checkoutDetailsService.detailsFields.ESIM)) {
          <div>
            <h3 class="mt-4 mb-2 section-title text-[--color-dark]">eSIM</h3>
            <p class="field-label text-[--color-gray] font-normal mb-4">
              <ngx-dynamic-hooks
                [content]="['checkout_phone_number', 'esim_text'] | translate"
                [options]="{ sanitize: false }"
              ></ngx-dynamic-hooks>
            </p>
            <lib-toggle-button
              [label]="['checkout_phone_number', 'esim_toggle_label'] | translate"
              class="mt-2"
              (toggleChange)="checkoutDetailsService.toggleActivateESIM()"
            ></lib-toggle-button>
          </div>
        }
      </div>
    </div>
  </lib-checkout-substep>
</lib-checkout-step>
