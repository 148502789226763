import { isPlatformBrowser, LocationStrategy } from '@angular/common';
import {
  AfterContentInit,
  APP_ID,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckoutService as MsCheckoutService, OrderHandlerService } from '@yol-digital/ms-client';
import { addDays, format } from 'date-fns';
import { switchMap } from 'rxjs';
import { BrandService } from 'brand';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutFlowService, InformationCardsEnum } from 'checkout-flow';
import { CheckoutSessionService } from 'checkout-session';
import { FeatureFlagService } from 'feature-flag';
import { SvgComponent } from 'icon';
import { ButtonComponent } from 'sakura-ui';
import { ToastService } from 'toast';
import { ReplaceTokenPipe, TranslatePipe, TranslateService } from 'translate';
import { DATE_MS_FORMAT } from 'utils';
import { InformationCardsComponent } from './information-cards/information-cards.component';

@Component({
  selector: 'lib-checkout-confirmation',
  templateUrl: './checkout-confirmation.component.html',
  imports: [SvgComponent, TranslatePipe, MatDialogModule, InformationCardsComponent, ReplaceTokenPipe, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutConfirmationComponent implements AfterContentInit {
  checkoutFlowService = inject(CheckoutFlowService);
  thankyouMessage = input(true);
  showInformationCards = input(true);
  buttonText = input('');
  buttonSvg = input(null);
  loadingPrimaryButton = input(false);
  private checkoutBaseService = inject(CheckoutBaseService);
  private checkoutSessionService = inject(CheckoutSessionService);
  private location = inject(LocationStrategy);
  private featureFlagService = inject(FeatureFlagService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private appId = inject(APP_ID);
  brand = inject(BrandService);
  private platformId = inject(PLATFORM_ID);
  firstName = '';
  email = '';
  orderNumber = signal<string>(null);
  orderStatus = signal<string>(null);
  signedContractStatus = signal<MsCheckoutService.PdfStatusEnum>(null);
  pdf = signal<string | null>(null);
  defaultDeliveryDate: string;
  deliveryText: string;
  activationText: string;
  checkoutFlow = this.checkoutFlowService.checkoutFlow;
  orderNotFinished = computed(
    () =>
      this.orderStatus() === 'IN_CREATION' ||
      this.orderStatus() === 'ORDER_ACCEPTED' ||
      this.orderStatus() === 'ORDER_IN_PROGRESS'
  );
  primaryButtonClick = output();

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
    }

    this.checkoutBaseService
      .getOrderStatus()
      .pipe(
        switchMap((status: OrderHandlerService.OrderStatusEnum) => {
          this.orderNumber.set(this.checkoutSessionService.orderId);
          this.orderStatus.set(status);
          return this.checkoutBaseService.getContractStatus('SIGNED_CONTRACT');
        })
      )
      .subscribe((pdfResp: MsCheckoutService.PdfResp) => {
        if (pdfResp.status === 'READY') {
          this.pdf.set(pdfResp.encodedPdf);
        }
        if (pdfResp.status === 'ERROR') {
          const message = this.translateService.getTranslation([
            'checkout_confirmation',
            'contract_error_' + this.appId,
          ]);
          this.toastService.add(message, false, 2000);
        }
        this.signedContractStatus.set(pdfResp.status);
      });
  }

  ngAfterContentInit(): void {
    this.firstName = this.checkoutSessionService.firstName;
    this.email = this.checkoutSessionService.contactEmail;
    this.orderNumber.set(this.checkoutBaseService.orderId || this.checkoutSessionService.orderId);
    this.orderStatus.set(this.checkoutSessionService.orderStatus);
    this.defaultDeliveryDate = format(
      addDays(new Date(), this.checkoutFlowService.checkoutFlow.deliveryDays),
      DATE_MS_FORMAT
    );

    this.deliveryText = this.getDeliveryText();
    this.activationText = this.getActivationText();

    this.filterInformationCards();
  }

  public ctaAction() {
    this.primaryButtonClick.emit();
  }

  private getDeliveryText() {
    if (this.checkoutSessionService.productSpecFamily === 'MOBILE' || this.checkoutSessionService.isMBB) {
      const isPorting = this.checkoutSessionService.phoneNumberOrderType === 'porting';
      const idValidated = this.checkoutSessionService.idStatus === 'valid';
      const fastActivation = this.checkoutSessionService.idFastActivation;
      const esimSelected = !!this.checkoutSessionService.eSim;

      if (!esimSelected && (idValidated || fastActivation)) return 'delivery_1_2_days';
      if (esimSelected && (idValidated || fastActivation || !isPorting)) return 'delivery_via_email';
      return 'delivery_after_id_approval';
    }
    if (this.defaultDeliveryDate === this.checkoutSessionService.wishDate) return 'delivery_content_default_date';

    return 'delivery_content_no_default_date';
  }

  private getActivationText() {
    if (this.checkoutSessionService.productSpecFamily !== 'MOBILE') return null;

    const isPorting = this.checkoutSessionService.phoneNumberOrderType === 'porting';
    const idValidated = this.checkoutSessionService.idStatus === 'valid';

    if (idValidated) {
      if (isPorting) return 'activation_after_delivery_on_porting_date';
      return 'activation_after_delivery';
    }

    if (isPorting) return 'activation_after_delivery_id_approval_on_porting_date';
    return 'activation_after_delivery_id_approval';
  }

  filterInformationCards() {
    this.filterTVInformationCards();
    this.filterOtoIdInformationCards();
    this.filterScanIdInformationCard();
    this.filterESIMInformationCard();
  }

  filterOtoIdInformationCards() {
    if (this.checkoutSessionService.productSpecClass === 'FIBER') {
      // byAddress or byOTOId
      const filledOtoId = this.checkoutSessionService.lineCheckQualifiedBy === 'byOTOId';
      if (filledOtoId) {
        this.checkoutFlow.informationCards = this.checkoutFlow.informationCards.filter(
          card =>
            card.value !== InformationCardsEnum.SET_YOUR_OTO_ID_UNAVAILABLE &&
            card.value !== InformationCardsEnum.SET_YOUR_OTO_ID_AVAILABLE
        );
      } else {
        if (['AVAILABLE', 'UNKNOWN'].includes(this.checkoutSessionService.otoIdState)) {
          this.checkoutFlow.informationCards = this.checkoutFlow.informationCards.filter(
            card => card.value !== InformationCardsEnum.SET_YOUR_OTO_ID_UNAVAILABLE
          );
        } else {
          this.checkoutFlow.informationCards = this.checkoutFlow.informationCards.filter(
            card => card.value !== InformationCardsEnum.SET_YOUR_OTO_ID_AVAILABLE
          );
        }
      }
    }
  }

  filterTVInformationCards() {
    const infoTvCard = InformationCardsEnum.USE_YOUR_TV_LOGIN;
    const infoTvCardExists = this.checkoutFlow.informationCards.some(({ value }) => value === infoTvCard);
    const productHasTv = this.checkoutSessionService.productCode?.toLowerCase().includes('_tv');

    if (productHasTv && !infoTvCardExists) {
      this.checkoutFlow.informationCards.push({ value: infoTvCard, hasCta: false });
    } else if (!productHasTv && infoTvCardExists) {
      this.checkoutFlow.informationCards = this.checkoutFlow.informationCards.filter(
        ({ value }) => value !== infoTvCard
      );
    }

    if (productHasTv && this.featureFlagService.isOn('show-free-tv')) {
      this.checkoutFlow.informationCards.push({ value: InformationCardsEnum.SHOW_FREE_TV_BOX, hasCta: false });
    }
  }

  filterScanIdInformationCard() {
    if (
      !this.checkoutFlow.informationCards.find(
        item => item.value === InformationCardsEnum.SCAN_ID || item.value === InformationCardsEnum.SCAN_ID_MBB
      )
    )
      return;

    const idDocumentStatus = this.checkoutSessionService.idStatus;
    const idScanSuccessful = this.checkoutSessionService.idScanSuccessful;

    // idDocumentStatus 'valid' or 'inProgress' means this is an existing customer and id scan was performed before this checkout session
    if (idScanSuccessful || idDocumentStatus === 'inProgress' || idDocumentStatus === 'valid') {
      this.checkoutFlow.informationCards = this.checkoutFlow.informationCards.filter(
        item => item.value !== InformationCardsEnum.SCAN_ID && item.value !== InformationCardsEnum.SCAN_ID_MBB
      );
    }
  }

  filterESIMInformationCard() {
    if (!this.checkoutFlow.informationCards.find(item => item.value === InformationCardsEnum.ESIM)) return;

    if (!this.checkoutSessionService.eSim || !this.featureFlagService.isOn('esim-feature')) {
      this.checkoutFlow.informationCards = this.checkoutFlow.informationCards.filter(
        item => item.value !== InformationCardsEnum.ESIM
      );
    }
  }

  downloadContract() {
    if (this.pdf()) {
      this.checkoutBaseService.generateDownloadLink(this.pdf());
    } else {
      this.checkoutBaseService.downloadContract('UNSIGNED_CONTRACT');
    }
  }
}
