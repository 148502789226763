import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'lib-backup-promotion-toast',
  imports: [CommonModule, TranslatePipe],
  templateUrl: './backup-promotion-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackupPromotionToastComponent {
  @Input() name: string;
  @Input() oldPrice: number;
  @Input() newPrice: number;
}
