import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import { catchError, EMPTY, finalize } from 'rxjs';
import { BrandService } from 'brand';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutDataAccessService } from 'checkout-data-access';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutLoadingService } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-number',
  imports: [TranslatePipe, SvgComponent],
  templateUrl: './new-number.component.html',
})
export class NewNumberComponent implements OnInit {
  private checkoutBaseService = inject(CheckoutBaseService);
  private checkoutDataAccessService = inject(CheckoutDataAccessService);
  private checkoutSessionService = inject(CheckoutSessionService);
  private flowService = inject(CheckoutFlowService);
  private loadingService = inject(CheckoutLoadingService);
  private cdr = inject(ChangeDetectorRef);
  private brandService = inject(BrandService);
  @Output() emitNewNumber = new EventEmitter<string>();
  newNumberLoading = false;
  newNumber: string;
  checkmarkIconSrc: string;

  ngOnInit(): void {
    this.checkmarkIconSrc = `check-mark-${this.brandService.brand}`;
    this.setAvailableNumber();
  }

  setAvailableNumber() {
    if (this.flowService.newPhoneNumber) {
      this.handleNewNumber(this.flowService.newPhoneNumber);
      return;
    }
    const newNumber = this.checkoutSessionService.msisdn;
    if (newNumber) {
      this.flowService.newPhoneNumber = newNumber;
      this.handleNewNumber(newNumber);
      return;
    }

    this.changeNewNumber();
  }

  handleNewNumber(newNumber: string, confirm = true) {
    this.newNumber = newNumber;
    this.emitNewNumber.emit(newNumber);
    if (confirm && !this.flowService.confirmNewNumberCalled) {
      this.checkoutDataAccessService
        .confirmNumber(newNumber)
        .subscribe(() => (this.flowService.confirmNewNumberCalled = true));
    }
  }

  changeNewNumber() {
    if (this.newNumberLoading) {
      return;
    }
    this.loadingService.showCTALoading();
    this.newNumberLoading = true;
    this.checkoutDataAccessService
      .changeNewNumber()
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            this.checkoutBaseService.handleError(err);
          }
          return EMPTY;
        }),
        finalize(() => {
          this.newNumberLoading = false;
          this.loadingService.hideLoading();
          this.cdr.markForCheck();
        })
      )
      .subscribe(newNumber => {
        this.flowService.confirmNewNumberCalled = true;
        this.flowService.newPhoneNumber = newNumber;
        this.handleNewNumber(newNumber, false);
      });
  }
}
