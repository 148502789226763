import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  prismicEndpoint: 'https://sunrise-yallo.cdn.prismic.io/api/v2',
  brand: 'yallo',
  fallbackLang: 'de',
  newMicroServiceEndpoint: 'https://ms.yallo.ch',
  websiteUrl: 'https://www.yallo.ch',
  allowedMsEnvs: [],
  eksMSEnvs: ['integration'],
  defaultUrlsEnvironment: '',
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  googleTagManagerId: 'GTM-N2RNF95',
  selfcareUrl: 'https://my.yallo.ch',
  threatMetrixConfig: {
    url: 't.yallo.ch',
    orgId: '35z6rce3',
  },
  sprinklerAppId: '6690eb6f187c274712e20ee8_app_220',
};
