import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterOutlet } from '@angular/router';
import { TranslatePipe } from 'translate';
import { NavigationComponent } from '../../components/navigation/navigation.component';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  imports: [NavigationComponent, RouterOutlet, MatDialogModule, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent {
  showLoginButton = signal(true);

  constructor() {}

  setShowLoginButton({ showLoginButton }: { showLoginButton: boolean }) {
    this.showLoginButton.set((showLoginButton === undefined || showLoginButton) ?? false);
  }
}
