<div class="sticky top-0 left-0 z-50 h-16 w-full flex kitsune-nav-bar">
  <div class="flex flex-nowrap justify-between items-center xl:container w-full px-3 xl:px-4">
    <div class="flex xl:z-10 h-full">
      <div [ngClass]="{ 'mr-[1rem]': !!logo }">
        @if (logo) {
          <div class="h-full">
            <a [href]="logoLink" (click)="showLeaveCheckoutAlert()">
              <img [src]="logo.url" [alt]="logo.alt" class="w-full h-full" />
            </a>
          </div>
        } @else {
          <a [href]="logoLink" (click)="showLeaveCheckoutAlert()">
            <lib-svg
              svg="brand/logo"
              class="extra-large"
              [ngClass]="{ 'logo-lebara ': brandService.isLebara(), 'logo white': brandService.isYallo() }"
            ></lib-svg>
          </a>
        }
      </div>
    </div>

    <div class="flex justify-center items-center gap-3">
      @if (showBasket) {
        <lib-checkout-basket-cta [hasBackupPromotionApplied]="hasBackupPromoApplied" />
      }
      <lib-language-selector [collapseOnMobile]="true" />
      @if (showLoginButton()) {
        <a
          (click)="existingCustomer ? navigateToAccountPage() : openLoginModal()"
          data-testid="account-button"
          class="flex items-center justify-evenly cursor-pointer"
        >
          <div
            class="border-solid border-[0.5px] border-[--blue-500] lg:border-[--color-border-primary] rounded-full h-12 w-12 lg:w-auto flex justify-center items-center px-4 hover:bg-[--color-primary-lightened-opacity] max-lg:bg-gradient-to-r max-lg:from-[--color-primary-light-from] max-lg:to-[--color-primary-light-to] max-lg:to-100%"
          >
            <lib-svg class="white lg:mr-3 hidden lg:block" svg="fontawesome/user-regular" />
            <p class="text-[--blue-500] lg:text-white font-bold text-xs xl:text-base">
              @if (existingCustomer) {
                <span class="hidden lg:block">
                  {{ ['login', 'my_account'] | translate }}
                </span>
                <span class="block lg:hidden">
                  {{ displayName }}
                </span>
              } @else {
                {{ ['login', 'login_button_label'] | translate }}
              }
            </p>
          </div>
        </a>
      }
    </div>
  </div>
</div>
