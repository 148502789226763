import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckoutService as MsCheckoutService } from '@yol-digital/ms-client';
import { addMonths, addYears, endOfToday, isValid, parse, startOfToday } from 'date-fns';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { CheckoutLoadingService } from 'checkout-loading';
import { FeatureFlagService } from 'feature-flag';
import {
  ButtonFormFieldComponent,
  DatePickerFormFieldComponent,
  dateValidator,
  FormFieldComponent,
  IdTypeFieldComponent,
  NationalityFieldComponent,
  RadioButtonFormFieldComponent,
  UserTitle,
  userTitles,
} from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe, TranslateService } from 'translate';
import { BrowserService, CountryService, DATE_MS_FORMAT, PaymentTypeEnum } from 'utils';
import { IdCheckService } from './id-check.service';
import PersonalIdDocument = MsCheckoutService.CheckoutDetailsV1PersonalIdDocument;
import PersonalDetails = MsCheckoutService.PersonalDetails;
import Product = MsCheckoutService.Product;

type BlurEventField = 'first_name' | 'last_name' | 'nationality' | 'id_type';

@Component({
  selector: 'lib-checkout-identification',
  imports: [
    SvgComponent,
    CommonModule,
    ButtonFormFieldComponent,
    DatePickerFormFieldComponent,
    DynamicHooksComponent,
    FormFieldComponent,
    TranslatePipe,
    ReactiveFormsModule,
    NationalityFieldComponent,
    IdTypeFieldComponent,
    RadioButtonFormFieldComponent,
  ],
  providers: [TitleCasePipe],
  templateUrl: './checkout-identification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutIdentificationComponent implements OnInit {
  private translateService = inject(TranslateService);
  private idCheckService = inject(IdCheckService);
  private browserService = inject(BrowserService);
  private countryService = inject(CountryService);
  private titleCasePipe = inject(TitleCasePipe);
  private featureFlagService = inject(FeatureFlagService);
  private destroyRef = inject(DestroyRef);
  private loadingService = inject(CheckoutLoadingService);
  private cdr = inject(ChangeDetectorRef);
  @Input({ required: true }) formGroup: FormGroup;
  @Input({ required: true }) productInformation: {
    productSpecCategory: Product['productSpecCategory'];
  };
  @Input({ required: true }) showFields = {
    scanIdCta: true,
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    nationality: true,
    idType: true,
    idNumber: true,
    idExpiryDate: true,
  };
  @Input({ required: true }) existingCustomer: boolean;
  @Input({ required: true }) identificationDocument: PersonalIdDocument | undefined;
  @Input({ required: true }) personalDetails: Partial<PersonalDetails> | undefined;
  @Input({ required: true }) sessionId: string;
  @Input({ required: true }) dateOfBirthOptions: {
    maxDateOfBirth: Date;
    minDateOfBirth: Date;
  };
  @Output() fieldErrorBlur = new EventEmitter<{ field: 'birth date'; control: AbstractControl }>();
  @Output() analyticsEvent = new EventEmitter<string>();
  @Output() fieldBlur = new EventEmitter<{ field: BlurEventField; value?: string }>();
  public startBirthdateDateAt: Date;
  public startIdCheckButtonDisabled = false;
  public minIdExpiryDate: Date;
  public maxIdExpiryDate: Date;
  public startIdExpiryDateAt: Date;
  public showScanId = true;

  ngOnInit() {
    this.showScanId = this.setShowScanId();

    this.initializeForm();
    this.fillForm();
    this.scanIdNowOrLater.valueChanges.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  private setShowScanId() {
    return this.showFields.scanIdCta;
  }

  public toggleScanIDOption(value: 'now' | 'later') {
    this.scanIdNowOrLater?.setValue(value);
    if (value === 'now') {
      this.loadingService.showCTALoading();

      this.startIdCheck();
    }
  }

  private blurEventCache: Record<BlurEventField, unknown> = {
    first_name: null,
    last_name: null,
    nationality: null,
    id_type: null,
  };

  public emitEvent(control: AbstractControl, field: BlurEventField) {
    if (control.valid) {
      const newValue = control.value;
      const oldValue = this.blurEventCache[field];

      if (newValue === oldValue) {
        return;
      }

      this.blurEventCache[field] = newValue;

      switch (field) {
        case 'first_name':
        case 'last_name':
          if (!this.existingCustomer) {
            this.fieldBlur.emit({ field });
          }
          break;

        case 'nationality':
          this.countryService
            .getCountries()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(countries => {
              const currentNationality = countries.find(item => item.alphaCode === this.nationality.value);
              this.fieldBlur.emit({ field, value: currentNationality.EN });
            });
          break;

        case 'id_type':
          this.countryService
            .getIdTypesByCountry(
              this.nationality.value,
              this.titleCasePipe.transform(this.productInformation.productSpecCategory) as PaymentTypeEnum
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(response => {
              const currentIdtype = response.find(item => item.id === this.idType.value);
              if (currentIdtype) {
                this.fieldBlur.emit({ field, value: currentIdtype.name.en });
              }
            });
          break;
      }
    }
  }

  private initializeForm() {
    if (this.showScanId) {
      this.formGroup.setValidators([Validators.required]);
    }
    if (this.showFields.idExpiryDate) {
      this.maxIdExpiryDate = addYears(endOfToday(), 25); // TODO: need to see what this should be set to
      this.minIdExpiryDate = startOfToday(); // TODO: need to see what this should be set to
      this.startIdExpiryDateAt = addMonths(startOfToday(), 12); // TODO: need to see what this should be set to
    }

    if (this.showFields.nationality) {
      this.formGroup.addControl('nationality', new FormControl(null, [Validators.required]));
    }
    if (this.showFields.idType) {
      this.formGroup.addControl('idType', new FormControl(null, [Validators.required]));
    }
    if (this.showFields.idNumber) {
      this.formGroup.addControl('idNumber', new FormControl(null, [Validators.required]));
    }
    if (this.showFields.idExpiryDate) {
      this.formGroup.addControl(
        'idExpiryDate',
        new FormControl(null, [
          Validators.required,
          dateValidator({ minDate: this.minIdExpiryDate, maxDate: this.maxIdExpiryDate }),
        ])
      );
    }
  }

  private fillForm() {
    if (this.personalDetails) {
      if (this.personalDetails.title) {
        this.title.setValue(this.personalDetails.title as UserTitle);
      } else {
        this.title.setValue(UserTitle.MR);
      }
      if (this.personalDetails.firstName) {
        this.firstName.setValue(this.personalDetails.firstName);
        this.firstName.markAsTouched();
      }
      if (this.personalDetails.lastName) {
        this.lastName.setValue(this.personalDetails.lastName);
        this.lastName.markAsTouched();
      }
      const dateOfBirthFromCheckoutSession = parse(this.personalDetails.birthday, DATE_MS_FORMAT, new Date());
      if (isValid(dateOfBirthFromCheckoutSession)) {
        this.dateOfBirth.setValue(dateOfBirthFromCheckoutSession);
        this.startBirthdateDateAt = dateOfBirthFromCheckoutSession;
        this.dateOfBirth.markAsTouched();
      }
    }
    const idDetails = this.identificationDocument?.documentDetails;
    if (idDetails) {
      if (this.showFields.nationality) {
        this.nationality.setValue(idDetails.nationality);
      }
      if (this.showFields.idType) {
        this.idType.setValue(idDetails.typeId);
      }
      if (this.showFields.idNumber) {
        this.idNumber.setValue(idDetails.number);
        this.idNumber.markAsTouched();
      }
      if (this.showFields.idExpiryDate) {
        this.idExpiryDate.setValue(parse(idDetails.expireDate, DATE_MS_FORMAT, new Date()));
        this.idExpiryDate.markAsTouched();
      }
      if (idDetails.status === 'later') {
        this.scanIdNowOrLater?.setValue(idDetails.status);
      }
    }
  }

  public get scanIdNowOrLater() {
    return this.formGroup.get('scanIdNowOrLater');
  }

  public get title() {
    return this.formGroup.get('title');
  }

  public get firstName() {
    return this.formGroup.get('firstName');
  }

  public get lastName() {
    return this.formGroup.get('lastName');
  }

  public get dateOfBirth() {
    return this.formGroup.get('dateOfBirth');
  }

  public get nationality() {
    return this.formGroup.get('nationality');
  }

  public get idType() {
    return this.formGroup.get('idType');
  }

  public get idNumber() {
    return this.formGroup.get('idNumber');
  }

  public get idExpiryDate() {
    return this.formGroup.get('idExpiryDate');
  }

  protected readonly userTitles = userTitles;

  public selectUserTitle(value: string) {
    this.formGroup.get('title').setValue(value);
  }

  public onIdentificationBlur(field: 'birth date', control: AbstractControl) {
    this.fieldErrorBlur.emit({ field, control });
  }

  public startIdCheck() {
    this.startIdCheckButtonDisabled = true;
    this.idCheckService
      .startIdCheckSession(this.sessionId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(redirectUrl => {
        this.analyticsEvent.emit('scan_id_initiated');
        this.browserService.redirect(redirectUrl);
      });
  }

  public get scanButtonText() {
    if (this.identificationDocument?.scanStatus === 'success') {
      return this.translateService.getTranslation(['checkout_details', 'redo']);
    }
    if (this.identificationDocument?.scanStatus === 'failed') {
      return this.translateService.getTranslation(['checkout_details', 'retry']);
    }
    return this.translateService.getTranslation(['checkout_details', 'scanID']);
  }

  public get scanInfoText() {
    if (this.identificationDocument?.scanStatus === 'success') {
      return this.translateService.getTranslation(['checkout_details', 'successful_scan_id_text']);
    } else {
      const productCategory = this.productInformation.productSpecCategory;
      const scanIdTranslation = `scan_id_text_${productCategory.toLowerCase()}`;
      return this.translateService.getTranslation(['checkout_details', scanIdTranslation]);
    }
  }

  public get scanIdStatusUpdated() {
    if (this.identificationDocument?.scanStatus === 'success') {
      return this.translateService.getTranslation(['checkout_details', 'done']);
    }
    if (this.identificationDocument?.scanStatus === 'failed') {
      return this.translateService.getTranslation(['checkout_details', 'failed']);
    }

    return null;
  }
}
