import { ChangeDetectionStrategy, Component, inject, input, OnChanges, signal, ViewChild } from '@angular/core';
import { OrderHandlerService } from '@yol-digital/ms-client';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { catchError, EMPTY } from 'rxjs';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutSessionService } from 'checkout-session';
import { ListItem, ListViewComponent } from 'list-view';
import { SignaturePadComponent } from 'signature-pad';
import { ToastService } from 'toast';
import { ReplaceTokenPipe, TranslatePipe, TranslateService } from 'translate';
@Component({
  selector: 'app-sign-contract',
  templateUrl: './sign-contract.component.html',
  imports: [
    ListViewComponent,
    TranslatePipe,
    ReplaceTokenPipe,
    SignaturePadComponent,
    DynamicHooksComponent,
    TranslatePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignContractComponent implements OnChanges {
  private checkoutSessionService = inject(CheckoutSessionService);
  private checkoutBaseService = inject(CheckoutBaseService);
  private translateService = inject(TranslateService);
  private toastService = inject(ToastService);
  @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
  contracts = input<{ contract: string; poaContract: string }>();
  orderStatus = input<
    | OrderHandlerService.OrderStatusRes
    | {
        [key: string]: string;
      }
  >();
  listItems = signal<ListItem[]>([]);
  hasSignature = signal(false);
  signatureSent = signal(false);
  showLoginButton = false;

  public get firstName() {
    return this.checkoutSessionService.firstName;
  }

  ngOnChanges() {
    this.setListItems();
  }

  setHasSignature(value: boolean) {
    this.hasSignature.set(value);
    this.setListItems();
  }

  setListItems() {
    this.listItems.set([
      {
        icon: 'fontawesome/file-pdf-o',
        title: this.translateService.getTranslation(['pos_checkout_contract_signature', 'brand_contract']),
        checked: this.hasSignature(),
        buttonText: this.translateService.getTranslation([
          'pos_checkout_contract_signature',
          'contracts_list_button_view_text',
        ]),
        buttonAction: () => {
          this.checkoutBaseService.openContract(this.contracts().contract);
        },
      },
    ]);
  }

  addSignature() {
    if (!this.signaturePad) return;
    const image = this.signaturePad.getSignature();
    if (!image) return;
    this.checkoutBaseService
      .addSign('CONTRACT_SIGNATURE', image)
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            this.toastService.add(
              this.translateService.getTranslation(['pos_checkout_contract_signature', 'add_sign_error']),
              false,
              1000
            );
          }
          return EMPTY;
        })
      )
      .subscribe(() => this.signatureSent.set(true));

    //TODO: call addSign again for POA contract
  }
}
