import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ListItem, ListItemComponent } from '../list-item/list-item.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-list-view',
  imports: [ListItemComponent],
  templateUrl: './list-view.component.html',
})
export class ListViewComponent {
  listItems = input<ListItem[]>();
}
