import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsisdnSearchService as Msisdn } from '@yol-digital/ms-client';
import { addDays, endOfToday, startOfToday } from 'date-fns';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { catchError, defer, EMPTY, map, Observable, of, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { AccordionComponent, AccordionItemComponent } from 'accordion';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutDataAccessService } from 'checkout-data-access';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutLoadingService } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { CheckoutStepComponent, CheckoutSubstepComponent } from 'checkout-step';
import { FeatureFlagService } from 'feature-flag';
import {
  AutocompleteFormFieldComponent,
  AutocompleteOption,
  DatePickerFormFieldComponent,
  dateValidator,
  FormFieldComponent,
  phoneNumberValidator,
  ToggleButtonComponent,
  toggledContentAnimation,
} from 'form-field';
import { LanguageService } from 'language';
import { TabComponent, TabGroupComponent } from 'tabs';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import {
  AddressLookupFields,
  BrowserService,
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  formatDateToMsFormat,
  InputLengthMonitorDirective,
} from 'utils';
import { NewNumberComponent } from './components/new-number/new-number.component';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  animations: [toggledContentAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CheckoutStepComponent,
    CheckoutSubstepComponent,
    AutocompleteFormFieldComponent,
    TabGroupComponent,
    TabComponent,
    FormsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    ToggleButtonComponent,
    InputLengthMonitorDirective,
    DatePickerFormFieldComponent,
    TranslatePipe,
    NewNumberComponent,
    DynamicHooksComponent,
    AccordionComponent,
    AccordionItemComponent,
  ],
})
export class PhoneNumberComponent implements OnInit, AfterViewChecked {
  private checkoutBaseService = inject(CheckoutBaseService);
  private checkoutDataAccessService = inject(CheckoutDataAccessService);
  checkoutSessionService = inject(CheckoutSessionService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  flowService = inject(CheckoutFlowService);
  loadingService = inject(CheckoutLoadingService);
  featureFlagService = inject(FeatureFlagService);
  private languageService = inject(LanguageService);
  private browserService = inject(BrowserService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  stepPath = 'phone-number';
  addressLookupFields?: AddressLookupFields;
  validatingNumber = false;
  validatingProvider = false;
  sameBrand = false;
  newNumber: string;
  portingRefId: string;
  numberForm: FormGroup;
  activeTab = this.flowService.activePhoneNumberTab;
  lastSubstep = false;
  autoFocus = false;
  minPortingDate = addDays(startOfToday(), 16);
  maxPortingDate = addDays(endOfToday(), 280);
  validators = {
    phoneNumber: [Validators.required, phoneNumberValidator(true)],
    date: [
      Validators.required,
      dateValidator({
        minDate: this.minPortingDate,
        maxDate: this.maxPortingDate,
        weekendsNotAllowed: true,
      }),
    ],
  };
  phoneNumberControl = new FormControl(null, [...this.validators.phoneNumber]);
  providerControl = new FormControl(null, [Validators.required]);
  dateControl = new FormControl(null, this.validators.date);
  selectedProvider: string;
  selectPortingDate = false;
  providersList$ = this.checkoutDataAccessService.getProviders().pipe(map(r => r as Msisdn.ProvidersResp[]));
  providers$ = this.providersList$.pipe(
    map(providers => providers.map(val => ({ value: val.providerId, label: val.providerName })))
  );
  portingError: string;
  customErrors = ['ERR_PORTING_ALREADY_IN_PROGRESS', 'ERR_INVALID_NUMBER', 'ERR_INVALID_PORT_NUMBER'];
  customErrorsMessages: Record<string, string> = {
    err_porting_already_in_progress: 'Porting is already in progress',
    err_invalid_number: 'This is not a valid mobile number that can be ported',
    err_invalid_port_number: 'This is not a valid mobile number that can be ported',
  };
  protected providerActionEvent$ = new Subject<string | boolean | 'selectProvider'>();
  activateEsim = false;
  codeCustomPatterns = {
    N: { pattern: new RegExp('[0-9]') },
    '0': { pattern: new RegExp('[0]') },
    '7': { pattern: new RegExp('[7]') },
  };
  isPhoneNumberValid = false;
  isSunrisePhoneNumber = signal(false);

  get isMobileView() {
    return !this.flowService.desktopView;
  }

  get sunriseNumberToCalltransformed() {
    return this.translateService
      .getTranslation(['checkout_phone_number', 'sunrise_number_phone_to_call'])
      .replaceAll(' ', '');
  }

  get postpaidProduct() {
    return this.checkoutSessionService.productSpecClass === 'POSTPAID';
  }

  get providerChosenIsSunrise() {
    return this.providerControl.value === '201008241110339248';
  }

  constructor() {
    const router = inject(Router);

    this.lastSubstep = !!router.getCurrentNavigation()?.extras?.state?.lastSubstep;
    // Make sure the new number tab is selected ONLY ON THE FIRST TIME user access phone-number for PREPAID
    if (this.flowService.activePhoneNumberTab === undefined) {
      if (this.checkoutSessionService.productSpecClass === 'PREPAID') {
        this.activeTab = 1;
      } else {
        this.activeTab = 0;
      }
    }
  }

  ngAfterViewChecked() {
    this.flowService.activePhoneNumberTab = this.activeTab;
  }

  ngOnInit() {
    this.numberForm = new FormGroup({
      phoneNumber: this.phoneNumberControl,
    });

    this.validatePortabilityForInputNumber();
  }

  selectProvider(option: AutocompleteOption) {
    if (option?.value !== this.selectedProvider) {
      this.selectedProvider = option?.value as string;
      this.providerActionEvent$.next('selectProvider');
    }
    this.providerControl.setErrors(null);
  }

  onBlur(control: AbstractControl) {
    if (control.value === null || control.value === '' || control.value === this.selectedProvider) {
      return;
    }
    this.providersList$.pipe(take(1)).subscribe(list => {
      const selectedProvider = list.find(provider => provider.providerId === control.value);

      if (selectedProvider) {
        this.selectProvider({ value: selectedProvider.providerId, label: selectedProvider.providerName });
      } else {
        this.providerControl.setErrors({ noProvider: true });
      }
    });
  }

  validatePortabilityForInputNumber() {
    this.providerActionEvent$
      .pipe(
        withLatestFrom(this.phoneNumberControl.statusChanges),
        tap(([action]) => {
          this.loadingService.showCTALoading();
          if (action !== 'selectProvider') {
            this.portingRefId = null;
            this.removeProvidersList();
          }
        }),
        switchMap(([val, status]) => {
          this.isSunrisePhoneNumber.set(false);
          this.isPhoneNumberValid = false;
          if (!!val && (status === 'VALID' || val === 'selectProvider')) {
            if (this.selectedProvider) this.validatingProvider = true;
            else this.validatingNumber = true;
            this.sameBrand = false;
            return this.checkoutDataAccessService
              .validatePortability(this.phoneNumberControl.value.replace(/ /g, ''), this.selectedProvider as string)
              .pipe(
                catchError(err => {
                  this.loadingService.hideLoading();
                  this.validatingNumber = false;
                  this.validatingProvider = false;
                  if (!err?.wasCaught) {
                    this.handlePortingErrors(err);
                  }
                  return EMPTY;
                })
              );
          }
          this.isPhoneNumberValid = false;
          this.loadingService.hideLoading();
          return EMPTY;
        })
      )
      .subscribe((res: Msisdn.PortValidateResp) => {
        this.loadingService.hideLoading();
        this.isPhoneNumberValid = true;
        this.portingRefId = res.portRefId;
        this.validatingNumber = false;
        this.validatingProvider = false;
        this.isSunrisePhoneNumber.set(res.isSunriseProvider);
        this.phoneNumberControl.setErrors(null);
        this.cdr.markForCheck();
      });
  }

  setCustomPortingError(error: string) {
    this.phoneNumberControl.setErrors({ [error]: true });
    this.phoneNumberControl.markAsTouched();
    this.autoFocus = true;
    this.cdr.markForCheck();
  }

  handlePortingErrors(err: HttpErrorResponse) {
    const error = err.error.error;

    this.checkoutBaseService.addAnalyticsEvent('error_occurred', {
      error_message: err?.message,
      error_code: error,
      http_status: '' + err?.status,
    });

    if (this.customErrors.includes(error)) {
      this.portingError = error.toLowerCase();
      this.checkoutBaseService.addAnalyticsEvent('form_field_error', {
        error_field_name: 'Your number',
        error_message: this.customErrorsMessages[this.portingError],
      });
      this.setCustomPortingError(this.portingError);
      return;
    }

    if (error === 'ERR_PROVIDER_INFO_NOT_FOUND') {
      this.showProvidersList();
      return;
    }

    if (error === 'ERR_CANNOT_PORT_FROM_SAME_BRAND') {
      this.sameBrand = true;
      this.cdr.markForCheck();
      return;
    }

    this.toastService.add(this.translateService.getTranslation(['checkout', 'errors', 'ERR_GENERIC_PORTING']), false);
  }

  get submitButtonText() {
    if (this.sameBrand && this.activeTab === 0) {
      return this.translateService.getTranslation(['checkout_confirmation', 'submit_button_to_your_account']);
    } else {
      return this.translateService.getTranslation(['checkout_details', 'submit_button_order_now']);
    }
  }

  showProvidersList() {
    this.numberForm.addControl('provider', this.providerControl);
    this.cdr.markForCheck();
  }

  removeProvidersList() {
    this.numberForm.removeControl('provider');
    this.providerControl.setValue(null);
    this.providerControl.markAsUntouched();
    this.selectedProvider = null;
    this.autoFocus = false;
    this.cdr.markForCheck();
  }

  toggleSelectPortingDate() {
    this.selectPortingDate = !this.selectPortingDate;
    if (this.selectPortingDate) {
      this.numberForm.addControl('date', this.dateControl);
    } else {
      this.numberForm.removeControl('date');
    }
    this.cdr.markForCheck();
  }

  get phoneNumber() {
    return this.numberForm.get('phoneNumber');
  }

  get date() {
    return this.numberForm.get('date');
  }

  onSubmitClick() {
    if (this.checkoutSessionService.isAuthenticated) {
      return this.checkoutBaseService.checkForUserSessionExpired().subscribe(expired => {
        if (!expired) {
          return this.submitPhoneNumber();
        }
        return;
      });
    }
    this.submitPhoneNumber();
  }

  submitPhoneNumber() {
    const isPortingTab = this.flowService.activePhoneNumberTab === 0;
    const phoneNumberFormValid = isPortingTab ? this.numberForm.valid : true;
    if (phoneNumberFormValid) {
      if (this.sameBrand && this.activeTab === 0) {
        if (this.checkoutSessionService.isAuthenticated) {
          this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}/account`);
        } else {
          this.checkoutBaseService.openLoginModal({
            existingUser: false,
            email: this.checkoutSessionService.contactEmail,
            redirectToSelfcare: true,
            disableMfaOnboarding: true,
          });
        }
      } else {
        this.loadingService.showCTALoading();

        const updateSIMTypeCall$ = this.checkoutDataAccessService.updateSIMType(this.activateEsim);
        let retryValidatePorting$: Observable<null | { portRefId: string }> = of(null);

        if (this.activeTab === 0 && !this.portingRefId) {
          retryValidatePorting$ = this.checkoutDataAccessService
            .validatePortability(this.phoneNumberControl.value?.replace(/ /g, ''), this.selectedProvider as string)
            .pipe(
              tap((res: { portRefId: string }) => {
                this.portingRefId = res.portRefId;
              }),
              catchError(err => {
                this.loadingService.hideLoading();
                this.validatingNumber = false;
                this.validatingProvider = false;
                if (!err?.wasCaught) {
                  this.handlePortingErrors(err);
                }
                return EMPTY;
              })
            );
        }

        const phoneNumberCall$ = this.activeTab === 0 ? this.submitKeepMyNumber() : this.submitNewNumber();

        retryValidatePorting$
          .pipe(
            switchMap(() => phoneNumberCall$),
            switchMap(() => updateSIMTypeCall$)
          )
          .subscribe({
            next: () => {
              this.loadingService.showProgressBarLoading();
              this.flowService.navigateToNextStep(this.stepPath);
            },
            error: err => {
              this.loadingService.hideLoading();
              if (!err?.wasCaught) {
                this.handlePortingErrors(err);
              }
            },
          });
      }
    } else {
      this.flowService.markFormTouched(this.numberForm);
    }
  }

  private submitNewNumber() {
    this.checkoutBaseService.addAnalyticsEvent('number_chosen', {
      phone_number_type: 'new_number',
      port_date_selected: 'no',
      esim_activated: this.activateEsim ? 'yes' : 'no',
    });
    return of(null);
  }

  private submitKeepMyNumber() {
    let portingDate: string = undefined;
    if (this.selectPortingDate) portingDate = formatDateToMsFormat(this.date);

    this.checkoutBaseService.addAnalyticsEvent('number_chosen', {
      phone_number_type: 'existing_number',
      port_date_selected: this.selectPortingDate ? 'yes' : 'no',
      esim_activated: this.activateEsim ? 'yes' : 'no',
    });
    return defer(() => this.checkoutDataAccessService.confirmPorting(this.portingRefId, portingDate));
  }

  public handleDebouncedKeyUp(event: string | boolean): void {
    this.providerActionEvent$.next(event);
  }

  public toggleActivateESIM() {
    this.activateEsim = !this.activateEsim;
  }
}
