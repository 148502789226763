import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { CheckoutBaseService } from 'checkout-base';
import { SvgComponent } from 'icon';
import { ModalsService } from 'modal';
import { CheckoutBasketModalComponent } from '../checkout-basket-modal/checkout-basket-modal.component';

type Theme = 'light' | 'dark';

@Component({
  selector: 'lib-checkout-basket-cta',
  imports: [CommonModule, SvgComponent, NgClass],
  templateUrl: './checkout-basket-cta.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutBasketCtaComponent {
  public hasBackupPromotionApplied = input(false);
  public theme = input<Theme>('light');
  private modalsService = inject(ModalsService);
  private checkoutBaseService = inject(CheckoutBaseService);

  openProductBasketModal() {
    this.modalsService
      .openDialog(CheckoutBasketModalComponent)
      .afterOpened()
      .subscribe(() => {
        this.checkoutBaseService.addAnalyticsEvent('basket_open');
      });
  }

  removeClass(el: HTMLElement) {
    el.classList.remove('animate-scale-bounce-lg');
    el.classList.remove('animate-scale-bounce-sm');
  }
}
