<!-- installation address HOME INTERNET -->
@if (installationAddress) {
  <h3 class="flex text-black mb-4 section-title" data-testid="installation-address">
    {{ ['checkout_details', 'installation_address'] | translate }}
  </h3>
  <div class="grid grid-rows-2 lg:grid-cols-2 !lg:w-1/2">
    <div class="lg:mr-32 flex flex-row lg:flex-col justify-between lg:mb-2 mb-3">
      <p class="text-[var(--color-gray-1)] text-base">
        {{ ['checkout_details', 'street_label'] | translate }}
      </p>
      <p class="mt-1 text-gray-2" data-testid="street-address">
        {{ installationAddress?.streetName }} {{ installationAddress?.streetNumber }}
      </p>
    </div>
    <div class="lg:mr-32 flex flex-row lg:flex-col justify-between lg:mb-2">
      <p class="flex-1 text-[var(--color-gray-1)] text-base">
        {{ ['checkout_details', 'post_code_label'] | translate }}
      </p>
      <p data-testid="zipCode" class="text-gray-2">
        {{ installationAddress?.postCode }} {{ installationAddress?.city }}
      </p>
    </div>
  </div>
}

@if (['MOBILE', 'TV'].includes(productFamily) || isMBB || title) {
  <h3 class="text-black mb-4 section-title" [ngClass]="{ 'lg:block hidden': !isAuthenticated && !title }">
    {{ title ?? (['checkout_details', 'billing_address'] | translate) }}
  </h3>
}
<!-- billing address -->
<ng-template #billingAddressLookupFormField>
  <lib-address-lookup-form-field
    [addressPrefill]="billingAddressPrefill"
    [disableInputFields]="false"
    [requiredValidation]="true"
    [isLinecheck]="false"
    [addressLookupFields]="addressLookupFields"
    class="w-full"
    [containerClasses]="addressLookupGridClasses"
    (postCodeValue)="setBillingAddressFormFields($event, 'postCode')"
    (streetValue)="setBillingAddressFormFields($event, 'street')"
    (streetNumberValue)="setBillingAddressFormFields($event, 'streetNumber')"
    (cityValue)="setBillingAddressFormFields($event, 'city')"
    (onBlur)="onAddressBlur('billing address', $event)"
  ></lib-address-lookup-form-field>
</ng-template>

@if ((['MOBILE', 'TV'].includes(productFamily) || isMBB) && isAuthenticated) {
  <div class="w-full container mb-4">
    @if (!isEditableField) {
      <div class="my-2">
        <label class="block text-[var(--color-gray-1)] text-base">
          {{ ['checkout', 'address'] | translate }}
        </label>
        <div class="flex items-center justify-between mt-1">
          <p>
            {{ checkoutSessionService?.billingAddress?.streetName }}
            {{ checkoutSessionService?.billingAddress.streetNumber }},
            {{ checkoutSessionService?.billingAddress.postCode }}
            {{ checkoutSessionService?.billingAddress?.city }}
          </p>
          <div class="flex items-center cursor-pointer order-last" (click)="editField()" data-testid="edit-icon">
            <lib-svg
              svg="fontawesome/edit"
              class="cursor-pointer ml-1 h-3 w-3 sm:w-4 sm:h-4 [&_svg]:w-full [&_svg]:h-auto fill-[--color-gray-2] block box-content"
            ></lib-svg>
            <span class="font-bold text-[#707070] text-sm sm:text-base mx-1">
              {{ ['checkout', 'edit'] | translate }}
            </span>
          </div>
        </div>
      </div>
    }
    <!-- Address Lookup Component -->
    @if (isEditableField) {
      <ng-content *ngTemplateOutlet="billingAddressLookupFormField" />
    }
  </div>
}

@if (showFields.billingAddress && !isAuthenticated) {
  <form [formGroup]="billingAddressForm" [ngClass]="{ 'my-3.5': productClass === 'HFC' || productClass === 'FIBER' }">
    @if (showBillingToggle && !['MOBILE', 'TV'].includes(productFamily) && !isMBB) {
      <lib-toggle-button
        label="{{ ['checkout_details', 'different_billing_address'] | translate }}"
        class="my-2"
        data-testid="billing-address-toggle"
        (toggleChange)="toggleDifferentAddresses('billing')"
        [value]="enableBilling?.value"
      ></lib-toggle-button>
    }
    @if (enableBilling?.value || productFamily === 'MOBILE' || isMBB) {
      <div
        [@toggledContent]
        class="w-full container md:p-0"
        [ngClass]="{ 'mt-8': productClass === 'HFC' || productClass === 'FIBER' }"
      >
        <ng-content *ngTemplateOutlet="billingAddressLookupFormField" />
        <div class="lg:grid lg:grid-cols-2 lg:gap-x-6">
          <lib-input-form-field
            class="w-full"
            label="{{ ['checkout_details', 'different_post_box_label'] | translate }}"
            type="text"
            formControlName="diffPostboxName"
            placeholder="{{ ['checkout_details', 'different_post_box_label'] | translate }}"
          >
          </lib-input-form-field>
        </div>
      </div>
    }
  </form>
}

@if (showFields.shippingAddress) {
  <form [formGroup]="shippingAddressForm" class="mb-10">
    <lib-toggle-button
      label="{{ ['checkout_details', 'different_shipping_address'] | translate }}"
      class="my-2"
      (toggleChange)="toggleDifferentAddresses('shipping')"
      [value]="enableShipping?.value"
      data-testid="shipping-toggle"
    ></lib-toggle-button>
    @if (enableShipping?.value) {
      <div [@toggledContent] class="w-full container md:p-0 mt-8" data-testid="shipping-address-form">
        <lib-address-lookup-form-field
          [addressPrefill]="shippingAddressPrefill"
          [disableInputFields]="false"
          [requiredValidation]="true"
          [isLinecheck]="false"
          [addressLookupFields]="addressLookupFields"
          class="w-full"
          [containerClasses]="addressLookupGridClasses"
          (postCodeValue)="setShippingAddressFormFields($event, 'postCode')"
          (streetValue)="setShippingAddressFormFields($event, 'street')"
          (streetNumberValue)="setShippingAddressFormFields($event, 'streetNumber')"
          (cityValue)="setShippingAddressFormFields($event, 'city')"
          (onBlur)="onAddressBlur('shipping address', $event)"
        ></lib-address-lookup-form-field>
        <div class="lg:grid lg:grid-cols-2 lg:gap-x-6">
          <lib-input-form-field
            class="w-full"
            label="{{ ['checkout_details', 'different_post_box_label'] | translate }}"
            type="text"
            formControlName="diffPostboxName"
            placeholder="{{ ['checkout_details', 'different_post_box_label'] | translate }}"
          >
          </lib-input-form-field>
        </div>
      </div>
    }
  </form>
}
