<div class="bg-white rounded-lg w-fit sm:w-[500px] shadow-lg py-2 mx-5">
  <div class="flex flex-col justify-center items-center mx-10">
    @if (name) {
      <p class="!font-bold">
        {{ ['checkout_details', 'greet_logged_in'] | translate }}
        {{ name }}!
      </p>
    } @else {
      <p class="!font-bold">{{ ['greeting'] | translate }}!</p>
    }
    <p class="" [innerHtml]="['checkout_details', 'message'] | translate"></p>
  </div>

  <div class="flex justify-center space-x-1 font-extrabold">
    <span class="text-red">CHF</span>
    <span>
      <span class="text-red"> {{ newPrice | number: '1.2-2' }}</span>
      <small
        ><span class="crossed-out"> {{ oldPrice }}.–</span></small
      ></span
    >
  </div>
</div>
