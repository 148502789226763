import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  @Input() length = 1;
  @Input() selected = 0;

  get array(): number[] {
    return new Array(this.length);
  }
}
