import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'lib-tab-group',
  templateUrl: './tab-group.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [MatTabsModule, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent {
  @ContentChildren(TabComponent) public tabs: QueryList<TabComponent>;
  @Output() activeTab = new EventEmitter<number>();
  @Input() selectedIndex = 0;

  onTabChanged(value: MatTabChangeEvent) {
    this.activeTab.emit(value.index);
  }
}
