<lib-checkout-step
  [stepTitle]="['checkout_phone_number', 'phone_sim_selector'] | translate"
  [stepPath]="stepPath"
  (nextStep)="onSubmitClick()"
  [loginModalClosed]="flowService.loginModalClosed$ | async"
  [productInformation]="{ productFamily: checkoutSessionService.productSpecFamily }"
>
  <lib-checkout-substep
    [substepTitle]="['checkout_phone_number', 'phone_selection'] | translate"
    [submitButtonText]="submitButtonText"
  >
    <div class="container md:p-0 overflow-x-hidden md:pt-7 lg:px-48">
      <!-- tabs -->
      <lib-tab-group (activeTab)="activeTab = $event" [selectedIndex]="activeTab">
        <lib-tab class="px-3">
          <div
            id="header"
            header
            class="section-title font-medium xs:text-nowrap text-balance"
            data-testid="existing-number"
          >
            {{ ['checkout_phone_number', 'keep_number'] | translate }}
          </div>
          <div class="overflow-y-hidden">
            <div class="flex flex-col">
              <form [formGroup]="numberForm">
                <div [ngClass]="{ 'mb-6': phoneNumberControl.errors?.['portingError'] }">
                  <lib-input-form-field
                    [loading]="validatingNumber"
                    [isBackendValid]="isPhoneNumberValid"
                    libInputLengthMonitor
                    [maxLength]="13"
                    (onLengthReached)="handleDebouncedKeyUp($event)"
                    class="w-full mt-10"
                    label="{{ ['checkout_phone_number', 'number_label'] | translate }}"
                    type="text"
                    formControlName="phoneNumber"
                    placeholder="{{ ['checkout_phone_number', 'enter_number'] | translate }}"
                    [autoFocus]="autoFocus"
                    data-testid="phone"
                    [mask]="'07N NNN NN NN'"
                    [maskCustomPatterns]="codeCustomPatterns"
                    [numberOfChars]="13"
                  >
                  </lib-input-form-field>
                </div>
                @if (numberForm.get('provider')) {
                  <lib-autocomplete-form-field
                    class="w-full"
                    [label]="['checkout_phone_number', 'provider'] | translate"
                    [loading]="validatingProvider"
                    [placeholder]="['checkout_phone_number', 'choose_provider'] | translate"
                    [options]="providers$ | async"
                    formControlName="provider"
                    (onBlur)="onBlur(providerControl)"
                    (optionSelected)="selectProvider($event)"
                  >
                  </lib-autocomplete-form-field>
                }
                <!-- POSTPAID && (isSunrisePhoneNumber OR choosen provider is Sunrise/UPC)-->
                @if (
                  featureFlagService.isOn('sunrise-prevention') &&
                  postpaidProduct &&
                  (isSunrisePhoneNumber() || providerChosenIsSunrise)
                ) {
                  <lib-accordion>
                    <lib-accordion-item [isOpen]="true" [isToggleEnabled]="isMobileView">
                      <div header class="flex justify-between font-bold text-[--color-gray-1]">
                        {{ ['checkout_phone_number', 'sunrise_number_title'] | translate }}
                      </div>
                      <div class="text-justify" [ngClass]="{ 'flex-row': !isMobileView, 'flex-col': isMobileView }">
                        <div>
                          <p class="mt-4 text-sm italic">
                            <span class="font-semibold text-gray-1"
                              >{{ ['checkout_phone_number', 'sunrise_number_content'] | translate }}
                            </span>
                            <a
                              [href]="'tel:' + sunriseNumberToCalltransformed"
                              class="text-sm font-semibold text-[--indigo-300] underline"
                            >
                              {{ ['checkout_phone_number', 'sunrise_number_phone_to_call'] | translate }}
                            </a>
                          </p>
                          <span class="italic text-sm text-[--color-petrol]">
                            {{ ['checkout_phone_number', 'sunrise_number_opening_hours'] | translate }}</span
                          >
                        </div>
                        <!-- <ngx-dynamic-hooks
                          data-source="prismic"
                          [content]="['checkout_phone_number', 'sunrise_number_content'] | translate"
                          [options]="{ sanitize: false }"
                        >
                        </ngx-dynamic-hooks> -->
                      </div>
                    </lib-accordion-item>
                  </lib-accordion>
                }

                @if (!sameBrand) {
                  <div class="flex flex-col text-gray-1 text-sm pb-8">
                    <ngx-dynamic-hooks
                      [content]="['checkout_phone_number', 'auto_porting_information'] | translate"
                      [options]="{ sanitize: false }"
                    ></ngx-dynamic-hooks>
                  </div>
                }
                @if (sameBrand) {
                  <div class="mb-52">
                    <p class="font-semibold text-[var(--color-gray-1)]">
                      <span class="!font-extrabold text-red text-[22px]"> !</span>
                      {{ ['checkout_phone_number', 'porting_same_brand_information1'] | translate }}
                    </p>
                    <p class="font-semibold text-[var(--color-gray-1)] mt-4">
                      {{ ['checkout_phone_number', 'porting_same_brand_information2'] | translate }}
                    </p>
                  </div>
                }

                @if (!sameBrand) {
                  <lib-toggle-button
                    class="my-4"
                    label="{{ ['checkout_phone_number', 'specific_date_porting'] | translate }}"
                    (toggleChange)="toggleSelectPortingDate()"
                  ></lib-toggle-button>
                }
                @if (selectPortingDate && !sameBrand) {
                  <lib-date-picker-form-field
                    [@toggledContent]
                    class="w-full mt-4"
                    label="{{ ['checkout_phone_number', 'date_label'] | translate }}"
                    formControlName="date"
                    [minDate]="minPortingDate"
                    [maxDate]="maxPortingDate"
                    [weekendsAllowed]="false"
                    placeholder="{{ ['checkout_phone_number', 'enter_date'] | translate }}"
                  >
                  </lib-date-picker-form-field>
                }
              </form>
            </div>
          </div>
        </lib-tab>
        <lib-tab>
          <div header class="section-title xs:text-nowrap text-balance" data-testid="new-number">
            {{ ['checkout_phone_number', 'new_number_tab_title'] | translate }}
          </div>
          @if (activeTab === 1) {
            <app-new-number (emitNewNumber)="newNumber = $event" />
          }
        </lib-tab>
      </lib-tab-group>
      @if (featureFlagService.isOn('esim-feature') && (!sameBrand || this.activeTab === 1)) {
        <h3 class="mt-4 mb-2 section-title text-[--color-dark]">eSIM</h3>
        <p class="field-label text-[--color-gray] font-normal mb-4">
          <ngx-dynamic-hooks
            [content]="['checkout_phone_number', 'esim_text'] | translate"
            [options]="{ sanitize: false }"
          ></ngx-dynamic-hooks>
        </p>
        <lib-toggle-button
          [label]="['checkout_phone_number', 'esim_toggle_label'] | translate"
          class="mt-2"
          (toggleChange)="toggleActivateESIM()"
          data-testid="esim-toggle"
        ></lib-toggle-button>
      }
    </div>
  </lib-checkout-substep>
</lib-checkout-step>
