import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { combineLatest, filter, map, switchMap } from 'rxjs';
import { AnalyticsService } from 'analytics';
import { UserService } from 'auth-data-access';
import { CheckoutLoadingService, CheckoutSkeletonLoadingComponent } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { MenuGlobalComponent } from 'menu';
import { ToastsComponent } from 'toast';
import { TooltipGlobalComponent } from 'tooltip';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    ToastsComponent,
    MenuGlobalComponent,
    RouterModule,
    NgClass,
    TooltipGlobalComponent,
    CheckoutSkeletonLoadingComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private checkoutSessionService = inject(CheckoutSessionService);
  private loadingService = inject(CheckoutLoadingService);
  private analyticsService = inject(AnalyticsService);
  private userService = inject(UserService);
  title = 'checkout';
  ctaLoading = false;
  skeletonLoading = false;
  progressBarLoading = false;

  constructor() {
    const cdr = inject(ChangeDetectorRef);

    this.loadingService.loading$.subscribe(({ cta, progressBar, skeleton }) => {
      this.ctaLoading = cta;
      this.skeletonLoading = skeleton;
      this.progressBarLoading = progressBar;

      cdr.markForCheck();
    });
    this.loadingService.showSkeletonLoading();

    //set visitor_id for analytics if there is no visitor_id
    const visitorId = this.userService.getVisitorId();
    if (!visitorId) {
      this.userService.setVisitorId();
    }

    this.setupAnalytics();
  }

  private setupAnalytics() {
    const visitorId = this.userService.getVisitorId();
    this.userService.getAccount().subscribe(account => {
      if (account) {
        const userDataAnalytics = this.analyticsService.buildUserData(account, visitorId);
        this.analyticsService.setUserData(visitorId, userDataAnalytics);
      } else {
        this.analyticsService.setUserData(visitorId);
      }
    });

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        switchMap((e: NavigationEnd) => {
          let page_type = e.url.search('confirmation') ? 'confirmation' : 'checkout';
          if (this.checkoutSessionService.productSpecClass) {
            page_type += ' ' + this.checkoutSessionService.productSpecClass.toLowerCase();
          }

          let route = this.route;
          while (route.firstChild) {
            route = route.firstChild;
          }

          return combineLatest([route.title, this.userService.getAccount()]).pipe(
            map(([page_title, account]) => ({ page_title, account, page_type }))
          );
        })
      )
      .subscribe(({ page_title, account, page_type }) => {
        if (account) {
          const userDataAnalytics = this.analyticsService.buildUserData(account, visitorId);
          this.analyticsService.pageView({ page_type, userDataAnalytics, visitorId, account, page_title });
        } else {
          this.analyticsService.pageView({ page_type, visitorId, page_title });
        }
      });
  }
}
