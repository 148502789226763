import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CheckoutFlowService } from 'checkout-flow';

@Component({
  selector: 'lib-checkout-substep',
  templateUrl: './checkout-substep.component.html',
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutSubstepComponent implements OnDestroy {
  private cdr = inject(ChangeDetectorRef);
  private checkoutFlowService = inject(CheckoutFlowService);
  @ViewChild('container') substepContainer: ElementRef;
  @Input() substepTitle: string;
  @Input() submitButtonText?: string;
  @Output() nextSubstep = new EventEmitter<void>();
  public active = false;
  private subscription: Subscription;

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public toNextSubstep() {
    this.nextSubstep?.emit();
  }

  set index(index: number) {
    this.subscription = this.checkoutFlowService.activeSubstepIndex$.subscribe(activeIndex => {
      this.active = index === activeIndex;
      this.cdr.markForCheck();
    });
  }
}
