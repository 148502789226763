import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { AccordionComponent, AccordionItemComponent } from 'accordion';
import { InformationCard } from 'checkout-flow';
import { TranslatePipe } from 'translate';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-information-cards',
  imports: [CommonModule, AccordionComponent, AccordionItemComponent, DynamicHooksComponent, TranslatePipe],
  templateUrl: './information-cards.component.html',
})
export class InformationCardsComponent implements OnInit {
  private cdr = inject(ChangeDetectorRef);
  @Input() informationCards: InformationCard[];
  isMobileView: boolean;

  constructor() {
    this.isMobileView = window.innerWidth < 768;
  }

  ngOnInit() {
    window.addEventListener('resize', () => {
      this.isMobileView = window.innerWidth < 768;
      this.cdr.detectChanges();
    });
  }
}
