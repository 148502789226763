@if (
  {
    loading: loadingService.loading$ | async,
    progress: loadingService.progressValue$ | async,
  };
  as $
) {
  @if ($.loading.progressBar) {
    <lib-checkout-loading-bar [progress]="$.progress"></lib-checkout-loading-bar>
  }
  <div class="container px-6 mt-6 flex flex-col">
    <div class="mb-8">
      <div class="flex flex-nowrap justify-between items-center xl:container w-full">
        @if (!firstStep || (!firstSubstep && !desktopView)) {
          <div
            id="back"
            class="flex items-center relative cursor-pointer"
            role="button"
            tabindex="0"
            (click)="back()"
            data-testid="back-arrow"
          >
            <lib-svg class="stroke-dark stroke-[var(--color-dark-gray)] block h-full mr-2" svg="arrow"></lib-svg>
            <p class="font-bold text-sm hidden lg:block">{{ ['login', 'back'] | translate }}</p>
          </div>
        }
        <div class="flex-auto text-center mb-2">
          <h2 class="hidden lg:block header-title">
            <ngx-dynamic-hooks [content]="stepTitle()" [options]="{ sanitize: false }"></ngx-dynamic-hooks>
          </h2>
          <h4 class="lg:hidden leading-[25px] header-title">
            <ngx-dynamic-hooks [content]="substepTitle" [options]="{ sanitize: false }"></ngx-dynamic-hooks>
          </h4>
        </div>
      </div>
      @if (totalSteps > 1) {
        <lib-stepper class="hidden lg:block" [length]="totalSteps" [selected]="stepIndex()"></lib-stepper>
      }
      <lib-stepper
        class="lg:hidden mb-6"
        [length]="totalSubsteps"
        [selected]="activeSubstepIndex + firstSubstepIndex()"
      ></lib-stepper>
    </div>
    <div class="self-stretch">
      <ng-content></ng-content>
    </div>
    @if (showBottomContent()) {
      <div class="flex justify-center" [ngClass]="{ hidden: !showAcceptanceTermsConditions }">
        <div class="w-full md:w-1/3 text-center text-gray-1 text-xs my-4" #acceptanceTermsConditionsContainer>
          <ng-content select="[acceptanceTermsConditions]"></ng-content>
        </div>
      </div>
      <div class="mt-10 mb-8 lg:px-48 w-full lg:w-4/5 ml-auto mr-auto max-w-[50rem] flex-1 content-end">
        @if (showLegalText) {
          <ngx-dynamic-hooks
            class="text-wrap [&_p]:text-[--color-gray-1] [&_p]:text-[11px] [&_p]:mb-4 [&_a]:text-[--color-purple]"
            [content]="termsConditionsContent()"
            [options]="{ sanitize: false }"
          >
          </ngx-dynamic-hooks>
        }
        <lib-button-form-field
          (click)="next()"
          size="full-width"
          [disabled]="$.loading.cta"
          class="disabled:[&>button]:!bg-[--color-gray-2] z-10 relative"
          [ngClass]="{ 'opacity-50 [&_.button:disabled]:!bg-[--color-primary]': $.loading.cta }"
          data-testid="logout"
        >
          <div [ngClass]="{ 'relative -left-[14px]': $.loading.cta }" class="flex justify-center items-center">
            @if ($.loading.cta) {
              <div
                class="inline-block h-6 w-6 animate-spin rounded-full border-3 border-solid border-current border-r-transparent align-[-0.375em] motion-reduce:animate-[spin_1.5s_linear_infinite] mr-1"
                role="status"
              ></div>
            }
            {{ submitButtonText }}
          </div>
        </lib-button-form-field>
      </div>
    }
  </div>
}
