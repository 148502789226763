<a
  (click)="openProductBasketModal()"
  data-testid="basket-button-open"
  class="group relative border-solid border-[0.5px] border-[--color-border-primary] rounded-full h-12 w-12 flex justify-center items-center cursor-pointer z-10 hover:bg-[--color-primary-lightened-opacity]"
>
  <div
    #refEl
    [ngClass]="{
      'group-hover:animate-scale-bounce-sm animate-scale-bounce-sm': !hasBackupPromotionApplied,
      'group-hover:animate-scale-bounce-lg animate-scale-bounce-lg': hasBackupPromotionApplied,
    }"
    (animationend)="removeClass(refEl)"
    class="table absolute -top-2 -right-1.5 bg-color-red text-[white] rounded-full text-xs h-4 w-[18px]"
  >
    <span class="table-cell align-middle text-center font-extrabold">1</span>
  </div>
  <lib-svg
    [ngClass]="theme() === 'light' ? 'fill-[--neutral-50]' : 'fill-[--neutral-800]'"
    svg="fontawesome/cart-shopping"
  />
</a>
